@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.confirmation-activation-detail {
  font-family: $font-family;
  overflow: hidden;

  &__headline-section {
    background-color: $color-background-light-primary;
    padding: 4rem 189px 0;
    text-align: center;
    transform: skewY(2deg);
    z-index: 2;
    position: relative;
    top: -15px;
    display: flex;
    justify-content: center;

    @include md {
      padding: 4rem 80px 0;
    }

    @include sm {
      padding: 4rem 20px 0;
    }

    &__skew {
      transform: skewY(-2deg);
    }

    &__headline {
      margin-bottom: 1.25rem;
      white-space: nowrap;

      @include sm {
        white-space: normal;
      }
    }

    &__text {
      margin-bottom: 3rem;
      color: $color-text-inverse-primary;

      &__bold {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $color-background-light-primary;
    padding: 50px 195px 3.75rem;
    position: relative;
    top: -50px;
    background: linear-gradient(135deg, #6C7073 0%, #333537 100%);

    @include md {
      padding: 50px 80px 3.75rem;
    }

    @include sm {
      padding: 50px 20px 3.75rem;
    }

    &__title {
      font-weight: $font-weight-bold;
      margin: $font-size-h1 0;
      text-align: center;
      padding-bottom: $font-size-h1;
      border-bottom: 1px solid #7E8183;
    }

    &__text {
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      line-height: $line-height;
      text-align: center;
    }

    &__bullet-box {
      margin-top: 20px;
      display: -webkit-box;

      &__number {
        background: $color-background-light-primary;
        border-radius: 50%;
        color: $color-background-dark-primary;
        font-weight: $font-weight-bold;
        display: inline-block;
        line-height: 20px;
        font-size: 10px;
        text-align: center;
        width: calc(2em + 1px);
        height: calc(2em + 1px);
        margin-right: 11px;
      }

      &__text {
        font-size: $font-size-small;
        max-width: 320px;
        line-height: $line-height-small;
      }
    }
  }
}