@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.product-list {
  position: relative;
  width: 100%;
  background-color: #202020;
  padding-bottom: 40px;

  &__background {
    position: absolute;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 534px;
    background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26)), url('../../static/connectivity-m53-hero.png');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__container {
    position: relative;
    z-index: 10;
    padding-top: 170px;

    width: 100%;
    text-align: center;

    &__inner {
      position: relative;
      max-width: 1140px;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__headline {
    margin-bottom: $font-size-base;
    font-family: $font-family;
    font-size: $font-size-headline;
    font-weight: $font-weight-bold;
    line-height: $line-height-headline;
    color: $color-text-primary;
    text-transform: uppercase;
  }

  &__description {
    font-family: $font-family;
    font-size: $font-size-extended;
    line-height: $line-height-extended;
    color: $color-text-primary;
  }

  &__items {
    display: flex;
    margin: 50px -18px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > .product-item-sizer {
      position: absolute;
      visibility: hidden;
      width: 33.33%;
    }

    > .product-item-box {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
}