@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.content {
  position: relative;
  display: flex;
  z-index: 0;
  width: 100%;
  min-height: calc(100vh - 270px);
  padding-top: 60px;

  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  color: $color-text-primary;
  background-color: $color-background-dark-primary;

  &--inverse {
    background-color: $color-background-light-primary;
  }

  &__inner {
    display: block;
    width: 100%;
    min-height: 100%;

    & > ul {
      display: block;
      list-style-type: disc;
      padding: 20px 60px;

      & > li {
        display: list-item;

        & > a {
          color: $color-action-primary;
          text-decoration: none;
          &:hover {
            color: $color-text-negative;
            text-decoration: underline;
          }
        }
      }
    }
  }
}