@import '../../styles/mixins.scss';

.container {
  @include container;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--wide {
    @include container--wide;
  }
}
