@import '../../styles/colors.scss';

.dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(37, 37, 37, .8);
}

.dialog {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.10);
  border-radius: 8px;

  &--light {
    background-color: $color-background-light-primary;
    border: 1px solid $color-border-light-primary;
  }
}
