@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.accordion-element {
  background-color: $color-background-light-primary;
  border: 1px solid $color-border-light-primary;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.06);
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.accordion-element__label {
  display: inline-block;
  width: 100%;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 30px 40px;
  cursor: pointer;

  &:hover {
    background-color: $color-background-light-secondary;
  }
}

.accordion-element__label-icon {
  margin-left: 20px;
  color: $color-primary;
  font-size: 16px;
  transition: all 0.5s;
  display: inline-block;
}

.accordion-element__content {
  max-height: 0;
  font-family: $font-family;
  font-size: 1.125 * $font-size-base;
  color: $color-text-inverse-primary;
  transition: all 0.5s;
  box-sizing: content-box;
  padding: 0 40px;
}

.accordion-element__checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked {
    + .accordion-element__label .accordion-element__label-icon{
      transform: rotate(-180deg);
    }
    ~ .accordion-element__content {
      max-height: 200px;
      padding: 0px 40px 30px;
      //overflow-y: scroll;
    }
  }
}
