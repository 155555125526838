@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

// ===== COMMON FOR ALL CUSTOM SELECTS =====

.custom-select {
  position: relative;
  display: inline-block;

  &--transformed {
    position: relative;

    select {
      display: none;
    }
  }
}

.custom-select__selected-item {
  position: relative;
  display: inline-block;
  padding: 6px;
  background-color: $color-background-light-primary;
  border: 1px solid $color-border-light-secondary;
  border-radius: 22px;
  height: 44px;

  &:hover {
    border-color: $color-primary;
  }

  &--active {
    background-color: $color-text-inverse-primary;
    border-color: $color-text-inverse-primary;

    &:hover{
      border-color: $color-text-inverse-primary;
    }
  }

  &--disabled {
    background-color: $color-gray;
    border-color: $color-gray;

    & ~.custom-select__arrow-icon {
      color: $color-text-inverse-secondary;
    }

    &:hover{
      border-color: $color-gray;
    }
  }
}

.custom-select__arrow-icon {
  position: absolute;
  top: 7px;
  right: 19px;

  color: $color-primary;
  transition: all .25s;
  z-index: 5;
  line-height: 19px;

  &--active {
    transform: rotate(180deg);
  }
}

.custom-select__options-container {
  position: absolute;
  left: 0;
  top: 60px;

  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.22);
  border-radius: 20px;
  z-index: 300;

  &--hidden {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: -6px;
    left: 40px;
    background-color: $color-background-light-primary;
    transform: rotate(45deg);
    z-index: -1;
  }
}

.custom-select__option {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid $color-border-light-primary;
  background-color: $color-background-light-primary;

  &:last-child {
    border: 0;
    border-radius: 0 0 20px 20px;
  }

  &:first-child {
    border-radius: 20px 20px 0 0;
  }

  &:hover {
    background-color: $color-background-light-secondary;
  }

  &--disabled {
    background-color: $color-gray;

    &:hover {
      background-color: $color-gray;
    }
  }
}

// CAR SELECT ONLY

.car-select__selected-item {
  padding-left: 80px;
  width: 240px;

  & .car-select__item-name {
    line-height: 1;
  }

  & .car-select__item-active--error {
    color: $color-error;
  }

  & .car-select__item-badge--error {
    background-color: $color-error;
  }
}

.custom-select__selected-item--active {
  & .car-select__item-name {
    color: $color-text-primary;
  }

  & .car-select__item-active--error {
    color: $color-text-inverse-secondary;
  }
}

.car-select__options-container {
  width: 280px;
}

.car-select__option {
  padding-left: 80px;

  &--disabled {
    background-color: $color-gray;

    &:hover {
      background-color: $color-gray;
    }
  }
}

.custom-select__option--selected {
  & .car-select__item-name {
    font-weight: $font-weight-bold;
  }
}

.car-select__item-image {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 70px;
  transform: translateY(-50%);
}

.car-select__item-badge {
  position: absolute;
  left: 14px;
  top: calc(50% - 8px);
  transform: translateY(-50%);

  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
  line-height: 15px;
  color: $color-text-primary;
  font-size: 12px;

  &--success {
    background-color: $color-primary;
  }

  &--error {
    font-size: 13px;
    background-color: $color-text-inverse-primary;
    padding-left: 3px;
  }
}

.car-select__item-name {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-inverse-primary;
  line-height: 1.375;
}

.car-select__item-vin {
  font-family: $font-family;
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
  line-height: 1.375;
}

.car-select__item-active {
  font-family: $font-family;
  font-size: $font-size-tiny;
  line-height: 1.375;

  &--success {
    color: $color-primary;
  }

  &--warning {
    color: $color-warnng;
  }

  &--error {
    color: $color-text-inverse-secondary;
  }
}

// LENGTH SELECT ONLY

.length-select__selected-item {
  width: 114px;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-primary;
  line-height: 30px;
  padding-left: 20px;
}

.custom-select__selected-item--active {
  color: $color-text-primary;
}

.custom-select__selected-item--disabled {
  color: $color-text-inverse-secondary;
}

.length-select__options-container {
  width: 140px;
  text-align: center;
}

.length-select__option {
  height: 44px;
  line-height: 24px;
  font-family: $font-family;
  font-size: $font-size-tiny;
  color: $color-text-inverse-primary;
}

.custom-select__option--selected {
  font-weight: $font-weight-bold;
  font-size: $font-size-tiny;
}

// ===== DISCLAIMERS =====

.car-select__disclaimer {
  margin-top: 20px;
  margin-bottom: 20px;

  font-family: $font-family;
  color: rgba(0, 0, 0, .7);
  font-size: $font-size;
  font-style: italic;
  max-width: 300px;

  &--strong {
    font-weight: $font-weight-bold;
  }
}