@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.to-cart-button {
  height: 44px;
  padding: 10px 32px;

  background-color: $color-background-light-primary;
  border: 1px solid $color-border-light-secondary;
  border-radius: 22px;

  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: $font-size-tiny;
  color: $color-text-inverse-primary;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    outline: none;
    border-color: $color-primary;
  }

  &:active {
    background-color: $color-text-inverse-primary;
    color: $color-text-primary;
    border-color: $color-text-inverse-primary;
  }

  &:disabled {
    background-color: $color-gray;
    color: $color-text-inverse-secondary;
    border: 0;
  }
}