@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.modal-box-product {
  &__container {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  &__background {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: $color-background-dark-secondary;
    opacity: .8;
  }

  &__inner {
    position: relative;
    z-index: 10;
    top: 50%;
    margin: 0 auto;
    margin-top: -275px;
    width: 750px;
    height: 630px;
    text-align: left;

    background-color: $color-background-light-primary;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  &__content {
    font-family: $font-family;
    padding: 40px 80px 80px 166px;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    color: $color-action-primary;

    &:hover {
      color: $color-text-negative;
    }
  }

  &__header {
    position: absolute;
    width: 100%;
    height: 70px;

    &__title {
      margin: 23px;
      padding: 0;
      font-family: $font-family;
      font-size: $font-size-h3;
      font-weight: $font-weight-bold;
      line-height: $line-height;
    }
  }
}