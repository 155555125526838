@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.payment-method-order-product-summary {
  display: flex;
  flex-direction: row;
  margin: $line-height 0;

  @include sm {
    flex-direction: column;
    text-align: center;
  }
}

.payment-method-order-product-summary__image {
  width: 70px;
  height: 44px;
  object-fit: cover;

  @include sm {
    align-self: center;
    margin-bottom: 1rem;
  }
}

.payment-method-order-product-summary__info-section {
  margin-left: 7px;
  font-family: $font-family;
  line-height: $spacing-offset;
}

.payment-method-order-product-summary__product-name {
  font-size: $font-size-small;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
}

.payment-method-order-product-summary__car-type {
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
  font-weight: $font-weight-bold;
}

.payment-method-order-product-summary__car-vin {
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
}

.payment-method-order-product-summary__timing {
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
}

.payment-method-order-product-summary__price {
  flex-grow: 1;
  text-align: right;
  font-family: $font-family;
  font-size: $font-size-tiny;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;

  @include sm {
    text-align: center;
  }
}
