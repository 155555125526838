@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.order-detail__link-back-section {
  margin-bottom: 20px;
}

.order-detail__headline-section {
  margin-bottom: 30px;
}

.order-detail__status-section {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order-detail__status {
  position: relative;
  display: inline-block;
  // margin-left: 30px;
  font-family: $font-family;
  font-size: $font-size;
  flex-grow: 1;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0px;
  //   left: -30px;
  //   width: 18px;
  //   height: 18px;
  //   border-radius: 100%;
  //   border: 2px solid $color-text-inverse-primary;
  // }

  &--success {
    color: $color-primary;
  }

  //   &:before {
  //     border-color: $color-primary;
  //   }
  // }

  &--warning {
    color: $color-warnng;
  }

  //   &:before {
  //     border-color: $color-warnng;
  //   }
  // }

  // &--error {
  //   color: $color-error;

  //   &:before {
  //     border-color: $color-error;
  //   }
  // }
}

.order-detail__status-icon {
  margin-right: 10px;
  font-size: 20px;

  &--success {
    color: $color-primary;
  }

  &--warning {
    color: $color-warnng;
  }
}

.order-detail__grid-section {
  margin: 30px 0;
}

.order-detail__summary-item {
  margin: 18px 10px 18px 18px;
}

.order-detail__price-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
