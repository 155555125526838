@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/breakpoints.scss';

.information-box {
  width: 557px;
  margin: 0 auto;
  position: relative;

  padding: 40px 0;

  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  background-color: $color-background-dark-secondary;
  text-align: center;

  z-index: 200;

  &--spaced {
    padding: 70px 0;
  }

  &--padded-sides {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include md {
    width: 519px;
  }

  @include sm {
    width: calc(100% - 40px);
    top: 0;
    left: 0;
    margin: 0 20px;
    z-index: 1;
  }

  &--light {
    z-index: auto !important;
    top: 60px;
    background-color: $color-background-light-primary;
    margin-bottom: 180px;
  }
}

.information-box__headline-text {
  font-size: $font-size-h2;
  font-weight: $font-weight-bold;
  color: $color-text-primary;
  padding: 0 40px;
  margin-bottom: 10px;
}

.information-box__text {
  color: $color-text-secondary;
  padding: 0 40px;
}

.information-box__divider {
  width: 100%;
  height: 1px;
  background-color: $color-background-dark-primary;
  margin: 40px 0;
}

.information-box__action-wrapper {
  margin: 40px 0;
}

.information-box__vehicle-row {
  margin-bottom: 30px;
  padding: 0 20px;

  > * {
    margin-right: 4px;
    margin-left: 4px;
    margin-bottom: 8px;
  }

  &--list {
    @include md {
      height: 54px;
      background-image: url('warning-icon.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
  
      > .vehicle-information--short {
        display: none !important;
      }
    }
  }  
}