@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.custom-select-one-option {
  position: relative;
  display: inline-block;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 22px;
  min-height: 44px;
  padding: 6px;

}

.car-select-one-option {
  padding-left: 80px;
  width: 220px;
}

.car-select-one-option__item-image {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 70px;
  transform: translateY(-50%);
}

.car-select-one-option__item-badge {
  position: absolute;
  left: 14px;
  top: calc(50% - 8px);
  transform: translateY(-50%);

  width: 18px;
  height: 18px;
  border-radius: 100%;
  color: $color-text-primary;
  font-size: 12px;
  padding: 2px;

  &--success {
    background-color: $color-primary;
  }

  &--error {
    font-size: 13px;
    background-color: $color-error;
    padding-left: 3px;
  }
}

.car-select-one-option__item-name {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-primary;
  line-height: 1;
}

.car-select-one-option__item-active {
  font-family: $font-family;
  font-size: $font-size-tiny;
  line-height: 1.375;
  color: $color-text-inverse-secondary;
}

.length-select-one-option {
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-primary;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
}