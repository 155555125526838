@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.order-detail-grid__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.order-detail-grid__box {
  width: 100%;
  padding: 20px;
  background-color: $color-background-light-secondary;

  &--row {
    display: flex;
    flex-direction: row;

    @include sm {
      display: block;
    }

    & .order-detail-grid__item {
      margin-bottom: 0px;
      margin-right: 40px;

      @include sm {
        margin-right: 10px;
      }
    }
  }

  &--half {
    width: calc(50% - 4px);
  }
}

.order-detail-grid__item {
  margin-bottom: 5px;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-primary;

  &:last-child {
    margin-bottom: 0;
  }

  &--strong {
    font-weight: $font-weight-bold;
  }

  &--spaced {
    margin-bottom: 15px;
  }
}
