@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

// ===== INPUT WITH FLOATING LABEL =====

.form__floating-input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;

  &--small {
    width: 30%;
  }
}

.form__floating-label, .form__floating-input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.form__floating-input {
  width: 100%;
  line-height: 48px;
  border: 1px solid $color-text-inverse-primary;
  border-width: 0px 0px 1px;
  font-size: $font-size-medium;
  color: $color-text-inverse-primary;
  padding: 0;

  &::placeholder {
    opacity: 0;
  }

  &:placeholder-shown {
    border-color: $color-text-inverse-secondary;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    border-color: $color-text-inverse-primary;
  }
}

.form__floating-label {
  color: $color-text-inverse-secondary;
  font-size: $font-size-tiny;
  font-family: $font-family;
}

.form__floating-input:placeholder-shown + .form__floating-label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 2.125rem) scale(1.275);
}

.form__floating-input:not(:placeholder-shown) + .form__floating-label,
.form__floating-input:focus + .form__floating-label {
  transform: translate(0, 0) scale(1);
}

// ===== VIN INPUT =====

$char-w: 1ch;
$gap: .5*$char-w;
$n-char: 17;
$in-w: $n-char*($char-w + $gap);

$background-focus: repeating-linear-gradient(90deg,
$color-text-inverse-primary 0, $color-text-inverse-primary $char-w,
transparent 0, transparent $char-w + $gap)
0 100%/ #{$in-w - $gap} 2px no-repeat;

$background-unfocus: repeating-linear-gradient(90deg,
$color-text-inverse-secondary 0, $color-text-inverse-secondary $char-w,
transparent 0, transparent $char-w + $gap)
0 100%/ #{$in-w - $gap} 2px no-repeat;

.form__vin-input {
  font-family: monospace;
  width: 100%;
  line-height: 48px;
  font-size: $font-size-medium;
  color: $color-text-inverse-primary;
  text-transform: uppercase;

	border: none;
	padding: 0;
	width: $in-w;
	background: $background-focus;
	font: 5ch monospace;
	letter-spacing: $gap;

  &::placeholder {
    opacity: 0;
  }

  &:placeholder-shown {
    background: $background-unfocus;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    background: $background-focus;
  }
}

// ===== SELECT =====

.form__select {
  width: 100%;
  padding: 13px 0;
  border: 1px solid $color-text-inverse-primary;
  border-width: 0px 0px 1px;
  font-size: $font-size-medium;
  color: $color-text-inverse-primary;
  appearance: none;
  position: relative;
  background-color: transparent;
  padding-right: 30px;

  &:active,
  &:hover,
  &:focus {
    outline: none;
    border-color: $color-text-inverse-primary;
  }
}

// ===== STANDARD INPUT =====

.form__input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;

  &--small {
    width: 30%;
  }
}

.form__label {
  color: $color-text-inverse-secondary;
  font-size: $font-size-tiny;
  font-family: $font-family;
}

.form__optional-indicator {
  position: absolute;
  top: 2px;
  right: 0;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-gray;
}

.form__input {
  width: 100%;
  height: 68px;
  padding-top: 20px;
  line-height: 48px;
  border: 1px solid $color-text-inverse-primary;
  border-width: 0px 0px 1px;
  font-size: $font-size-medium;
  color: $color-text-inverse-primary;

  &::placeholder {
    color: $color-text-inverse-secondary;
    font-size: $font-size;
  }

  &:placeholder-shown {
    border-color: $color-text-inverse-secondary;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    border-color: $color-text-inverse-primary;
  }

  &--small {
    width: 30%;
  }
}

.form__select-arrow-icon {
  position: absolute;
  top: 30px;
  right: 10px;
  color: $color-primary;
  font-size: 18px;
}

// ===== CHECKBOX =====

.checkbox__container {
  display: block;
  position: relative;
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-inverse-primary;
  line-height: 1.5;

  padding-left: 25px;
  cursor: pointer;
  user-select: none;

  &--link {
    color: $color-primary;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: underline;
    }
  }

  .checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__checkmark {
      background-color: $color-primary;
      border-color: $color-primary;

      &:after {
        display: block;
      }
    }

    &:checked ~ .checkbox__bold-text {
      font-weight: $font-weight-bold;
    }
  }

  .checkbox__checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $color-background-light-primary;
    border: 1px solid $color-gray;

    &:hover {
      border-color: $color-text-inverse-primary;
    }

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 4px;
      top: -1px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(32deg);
    }
  }
}
