$color-primary: #4BA82E;


$color-background-dark-primary: #202020; // page background for dark pages
$color-background-dark-secondary: #393939; // modal background for dark pages

$color-background-light-primary: #FFFFFF; // page background for light pages
$color-background-light-secondary: #F3F6F6; // box background for light pages
$color-border-light-primary: #E5E5E5; // borders and dividers in shopping cart, dividers in user section
$color-border-light-secondary: #D5DADD; // borders in user section

$color-text-primary: rgb(255, 255, 255);
$color-text-negative: #292929;
$color-text-secondary: rgba(244, 246, 246, 0.6);

$color-text-inverse-primary: #4B4A4B; // primary font color on light pages
$color-text-inverse-secondary: #808285; // secondary font color on light pages

$color-action-primary: #4ba82e;

$color-background-footer: #000000; // footer background color
$color-background-footer-navigation: #4B4A4B;


$color-background-info: #FFFEE0;
$color-border-info: rgba(142, 140, 68, .21);

$color-gray: #C0C0C0;
$color-error: #B63534;
$color-warnng: #E77632;

$color-button-primary: $color-primary;
$color-button-primary-hover: #409129;
$color-button-primary-click: $color-text-inverse-primary;
$shadow-button-primary: 0 6px 12px 0 rgba(75,168,46,0.20), 0 2px 4px 0 rgba(75,168,46,0.27);

$box-shadow: 0 3px 5px 0 rgba(0,0,0,0.05);
$header-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);