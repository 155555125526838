@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.order-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 7px;
  padding: 24px;
  height: 64px;

  background-color: $color-background-light-primary;
  border: 1px solid $color-border-light-secondary;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.05);

  @include sm {
    height: 100%;
    padding: 12px;
    flex-direction: column;
    white-space: nowrap;
    align-items: flex-start;
  }
}

.order-box__item {
  font-family: $font-family;
  font-size: $font-size-xsmall;
  color: $color-text-inverse-primary;

  &--strong {
    font-size: $font-size;
    font-weight: $font-weight-bold;
  }

  &--row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--right {
    text-align: right;
    justify-content: flex-end;

    @include sm {
      justify-content: flex-start;
    }
  }

  &--wide {
    width: 40%;
  }

  &--middle {
    width: 25%;
  }

  &--small {
    width: 17.5%;
  }
}

.order-box__status {
  font-size: $font-size-xsmall;

  &--success {
    color: $color-primary;
  }

  &--warning {
    color: $color-warnng;
  }

  &--error {
    color: $color-error;
  }
}

.order-box__next-arrow {
  margin-left: 30px;
  color: $color-primary;

  @include sm {
    display: none;
  }
}
