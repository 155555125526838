@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.error {
  font-family: $font-family;
  text-align: left;
  color: $color-text-inverse-primary;

  &__text {
    margin: 2rem 0;
    opacity: 0.6;
  }

}
