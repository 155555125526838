$breakpoint-phone-max: 575px;           // phones => 0 - 575px
$breakpoint-tablet-max: 991px;          // tablets => 576px - 991px
$breakpoint-small-desktop-max: 1199px;   // small desktops => 992px - 1199px
                                        // (default) big desktops => 1200 and more

@mixin lg {
  @media (max-width: #{$breakpoint-small-desktop-max}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-tablet-max}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-phone-max}) {
    @content;
  }
}

/* ===== EXAMPLE USAGE =====

.container {
  padding: 0 15px;
  @include lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;

  @include sm {
    margin-right: auto;
  }

  @include md {
    margin-right: auto;
  }

  @include lg {
    margin-right: auto;
  }
}

============================ */
