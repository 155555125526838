@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.order-summary-detail-grid__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    align-items: center;
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.order-summary-detail-grid__box {
  width: calc(50% - 4px);
  padding: 20px;
  background-color: $color-background-light-secondary;

  @include sm {
    width: 100%;
    margin-bottom: 1rem;
  }

  &:not(:first-child) {
    margin-left: 8px;

    @include sm {
      margin-left: 0;
    }
  }

  &--row {
    display: flex;
    flex-direction: row;

    & .order-summary-detail-grid__item {
      margin-bottom: 0px;
      margin-right: 40px;
    }
  }
}

.order-summary-detail-grid__item {
  margin-bottom: 5px;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-primary;

  &:last-child {
    margin-bottom: 0;
  }

  &--strong {
    font-weight: $font-weight-bold;
  }

  &--spaced {
    margin-bottom: 15px;
  }
}