@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.user-address-delete-dialog__headline-section {
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-address-delete-dialog__content-section {
  padding: 30px 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:first-child {
    margin-bottom: 30px;
  }

  > * {
    margin: 15px 0;
  }

  @include sm {
    padding: 30px 30px;
  }
}

.user-address-delete-dialog__close-icon {
  position: relative;
  font-size: 24px;
  color: $color-primary;
}
