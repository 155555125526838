@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.example_container {
  width: 100%;
  margin: 50px 0;
  text-align: center;
}

.example {
  font-family: $font-family;
  font-size: $font-size-headline;
  color: $color-text-inverse-primary;
  text-align: center;
}
