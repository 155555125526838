@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.shopping-cart-layout {
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  justify-content: center;
}
.shopping-cart-layout__content-section {
  padding: 80px 150px 60px;
  margin-bottom: 60px;
  flex-grow: 1;
  background-color: $color-background-light-primary;

  @include md {
    padding: 80px 20px 60px;
  }
}

.shopping-cart-layout__content-wrapper {
  @include container;

  height: 100%;
}