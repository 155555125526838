@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.user-menu__item {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-inverse-primary;
  margin: 15px 0;

  &--active {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }

  &--spaced {
    margin-top: 44px;
  }
}

.user-menu__link {
  text-decoration: none;
  color: inherit;

  &:hover {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }

  &:focus {
    outline: none;
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
}
