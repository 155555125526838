@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.order-summary-layout {
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  justify-content: center;
  background-color: $color-background-dark-primary;
}

.order-summary-layout__nav-section {
  padding-top: 50px;
  background-color: $color-background-dark-primary;
  z-index: 1;
}

.order-summary-layout__content-section {
  margin-top: 50px;
  padding: 0 100px;
  margin-bottom: 80px;
  flex-grow: 1;
  background-color: $color-background-dark-primary;
  z-index: 1;

  @include md {
    padding: 0 60px 80px;
  }

  @include sm {
    padding: 0 30px 80px;
  }
}

.order-summary-layout__content-wrapper {
  max-width: 750px;
  margin: 0 auto;
}