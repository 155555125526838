@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.order-summary-detail {
  background-color: $color-background-light-primary;
  font-family: $font-family;
  padding: 50px 100px 80px;
  z-index: 2;

  @include md {
    padding: 50px 50px 80px;
  }

  &__headline {
    margin-bottom: 1.75rem;
  }

  &__title {
    padding-bottom: $spacing-offset;
  }

  &__title--padding {
    padding: $spacing-offset 0;
  }

  &__online-payment-box {
    padding: $spacing-offset 0;
    display: flex;
    justify-content: space-between;

    &__text,
    &__price {
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      color: $color-text-inverse-primary;
    }
  }

  &__divider-space {
    margin-top: $line-height-extended;
  }

  &__price-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4rem;
  }

  &__button-section {
    display: flex;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
      justify-content: center;
    }
  }
}