$font-family: 'SkodaNext', sans-serif;

$font-weight-thin: 100;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 800;
$font-weight-black: 900;

$font-size-base: 1rem; // 16px

$spacing-offset: 1rem; // 16px

$font-size-headline: 3 * $font-size-base; // 48px
$line-height-headline: 3.375 * $font-size-base; // 54px

$font-size-h1: 2 * $font-size-base; // 32px
$line-height-h1: 2.3 * $font-size-base; // 37px
$font-size-h2: 1.5 * $font-size-base; // 24px
$line-height-h2: 1.75 * $font-size-base; // 28px
$font-size-h3: 1.25 * $font-size-base; // 20px
$line-height-h3: 1.5 * $font-size-base; // 24px
$font-size-h4: 1 * $font-size-base; // 16px
$font-size-medium: 1.375 * $font-size-base; // 22px

$font-size-small: 0.875 * $font-size-base; // 14px
$line-height-small: 1.25 *  $font-size-base; // 20px

$font-size-xsmall: 0.8125 * $font-size-base; // 13px
$font-size-tiny: 0.75 * $font-size-base; // 12px

$font-size: $font-size-base; // 16px
$line-height: 1.375 *  $font-size-base; // 22px



$font-size-extended: 1.25 * $font-size-base; // 16px
$line-height-extended: 1.95 *  $font-size-base; // 31px

$font-size-button: 0.75 * $font-size-base; // 12px