@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.footer {
  width: 100%;
  height: 72px;
  color: $color-gray;
  background-color: $color-background-footer;
  font-family: $font-family;
  font-size: $font-size-tiny;
  line-height: $line-height;

  &__inner {
    @include container--wide;

    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 60px;

    @include sm {
      padding: 0 15px;
    }
  }

  &__block {
    width: 30%;
    align-self: center;
  }

  &__social-icons {
    text-align: center;
    a {
      display: inline-block;
      padding: 2px 5px;
      margin: 0 5px;
      text-decoration: none;
      color: $color-text-inverse-primary;

      &:hover {
        color: $color-text-primary;
      }
    }
  }
}
