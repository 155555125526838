@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.shopping-cart-detail {
  background-color: $color-background-light-primary;

  &__headline-section {
    margin-bottom: 40px;
  }

  &__summary-section {
    margin-bottom: $line-height;
  }

  &__price-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $line-height-extended;
  }

  &__button-section {
    display: flex;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
      justify-content: center;
    }
  }
}