@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.order-summary-price {
  font-family: $font-family;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  color: $color-text-inverse-secondary;
  line-height: 1.7;
  margin-top: $spacing-offset;
  margin-bottom: -10px;
  width: 100%;
  border-spacing: 0 !important;

  &__item {
    text-align: left;
    white-space: nowrap;
  }

  &__label {
    font-size: $font-size-tiny;

    &--strong {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      color: $color-text-inverse-primary;
    }
  }

  &__value {
    text-align: right;

    &--strong {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      color: $color-text-inverse-primary;
    }
  }
}