@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.user-address {
  border: 1px solid $color-border-light-secondary;
  box-shadow: $box-shadow;
  padding: 24px;
}

.user-address__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &--actions {
    justify-content: flex-end;
  }

  @include sm {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.user-address__address-container {
  padding-bottom: 20px;
}

.user-address__item {
  font-family: $font-family;
  color: $color-text-inverse-primary;
  font-size: $font-size-small;
  line-height: 1.4;

  &--strong {
    font-size: $font-size;
    font-weight: $font-weight-bold;
  }
}

.user-address__status {
  &--primary {
    font-family: $font-family;
    font-size: $font-size-tiny;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $color-text-inverse-primary;
    letter-spacing: 1px;
  }
}
