.user-addresses__headline-section {
  margin-bottom: 40px;
}

.user-addresses__address-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.user-addresses__address-list-item {
  width: calc(50% - 15px);
  margin-bottom: 30px;
}
