@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.headline {
  font-family: $font-family;
  font-size: $font-size-h1;
  font-weight: $font-weight-bold;
  line-height: $line-height-h1;
  color: $color-text-primary;

  @include md {
    text-align: center;
  }

  &--inverse {
    color: $color-text-inverse-primary;
  }

  &--small {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
  }
}
