@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/breakpoints.scss';

.information-box-layout__inner {
  position: relative;
  padding-top: 60px;
  min-height: calc(100vh - 270px);
  background-color: $color-background-dark-primary;
  display: flex;
  flex-direction: column;
}

.information-box-layout__image-section {
  position: relative;
  height: 400px;
  padding: 119px;
  background-image: url('../../static/connectivity-m53-hero.png');
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-image: url('InformationLayoutImageBorder.png');
    background-size: cover;
  }

  @include md {
    height: 322px;
    padding: 70px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:after {
      display: none;
    }
  }

  @include sm {
    padding: 40px 20px;
    background: none;
    height: 200px;
  }
}

.information-box-layout__content-section {
  margin-top: -120px;
  flex-grow: 1;
  padding-bottom: 80px;

  @include md {
    margin-top: -60px;
  }

  @include sm {
    margin-top: 0;
  }
}

.information-box-layout__title {
  font-size: $font-size-headline;
  font-weight: $font-weight-bold;
  color: $color-text-primary;
  text-transform: uppercase;

  @include sm {
    font-size: $font-size-h1;
  }
}

.information-box-layout__subtitle {
  font-size: $font-size-h2;
  font-weight: $font-weight-light;
  color: $color-text-primary;

  @include sm {
    font-size: $font-size-h3;
  }
}