@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.payment-method-order-summary {
  background-color: $color-background-light-secondary;
  padding: $line-height-h1;
  font-family: $font-family;

  @include md {
    margin-top: 3rem;
  }

  &__title {
    color: $color-text-inverse-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    margin-bottom: $spacing-offset;
    margin-top: -5px;

    @include sm {
      text-align: center;
    }
  }

  &__online-payment-box {
    padding: $spacing-offset 0;
    display: flex;
    justify-content: space-between;
  }

  &__text,
  &__price {
    font-size: $font-size-small;
    color: $color-text-inverse-primary;
    font-weight: $font-weight-bold;
  }
}