@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.payment-method-detail {
  background-color: $color-background-light-primary;

  &__headline-section {
    margin-bottom: 2.5rem;
  }

  &__inner {
    display: flex;
    flex-direction: row;

    @include md {
      flex-direction: column;
      align-items: center;
    }

    &__payment-section {
      padding-right: 4rem;
      flex-grow: 1;
      max-width: 630px;

      @include md {
        padding-right: 0;
      }
    }

    &__summary-section {
      flex-grow: 1;
      max-width: 440px;
    }
  }
}