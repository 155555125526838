@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.vehicle-information {
  position: relative;
  display: inline-block;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 22px;
  min-height: 44px;
  padding: 6px 20px 6px 80px;
  text-align: left;

  &--short {
    padding-right: 6px;
  }
}

.vehicle-information__image {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 70px;
  transform: translateY(-50%);
}

.vehicle-information__badge {
  position: absolute;
  left: 14px;
  top: calc(50% - 8px);
  transform: translateY(-50%);

  width: 19px;
  height: 19px;
  border-radius: 100%;
  color: $color-text-primary;
  font-size: 12px;

  &--success {
    background-color: $color-primary;
  }

  &--error {
    font-size: 13px;
    background-color: $color-error;
    padding-left: 3px;
  }
}

.vehicle-information__name {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-primary;
  line-height: 1;
}

.vehicle-information__active {
  font-family: $font-family;
  font-size: $font-size-tiny;
  line-height: 1.375;
  color: $color-text-inverse-secondary;
}
