@import '../../styles/colors.scss';

.user-vehicle-enroll-confirm-dialog__headline-section {
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-vehicle-enroll-confirm-dialog__content-section {
  min-width: 950px;
  padding: 97px 0 81px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-vehicle-enroll-confirm-dialog__close-icon {
  font-size: 24px;
  color: $color-primary;
}

.user-vehicle-enroll-confirm-dialog__image {
  margin-bottom: 55px;
}

.user-vehicle-enroll-confirm-dialog__text {
  max-width: 320px;
  text-align: center;
}

.user-vehicle-enroll-confirm-dialog__actions {
  margin-top: 66px;
}