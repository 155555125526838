@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagination__item {
  width: 28px;
  height: 28px;
  padding: 0;
  margin-left: 5px;

  line-height: 24px;
  text-align: center;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: $font-size-tiny;

  background-color: $color-background-light-primary;
  border-radius: 100%;
  color: $color-text-inverse-primary;
  border: 1px solid $color-gray;
  cursor: pointer;

  &:hover {
    border-color: $color-text-inverse-primary;
    background-color: $color-background-light-primary;
  }

  &:focus {
    outline: none;
    border-color: $color-text-inverse-primary;
    background-color: $color-background-light-primary;
  }

  &:active {
    border-color: $color-text-inverse-primary;
    background-color: $color-text-inverse-primary;
    color: $color-text-primary;
  }

  &--active {
    border-color: $color-text-inverse-primary;
    background-color: $color-text-inverse-primary;
    color: $color-text-primary;

    &:hover {
      border-color: $color-text-inverse-primary;
      background-color: $color-text-inverse-primary;
      color: $color-text-primary;
    }

    &:focus {
      border-color: $color-text-inverse-primary;
      background-color: $color-text-inverse-primary;
      color: $color-text-primary;
    }
  }
}

.pagination__next-arrow {
  color: $color-text-inverse-primary;

  &:active {
    color: $color-text-primary;
  }
}
