.user-address-form {
  max-width: 550px;
}

.user-address-form__line {
  margin-bottom: 10px;
}

.user-address-form__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
