@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__tab {
    margin-right: 40px;

    @include sm {
      white-space: nowrap;
    }
  }

  &__tab-link {
    position: relative;
    padding-bottom: 10px;

    font-family: $font-family;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $color-text-inverse-secondary;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;

    background-color: transparent;
    border: none;

    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      width: 0px;
      height: 3px;
      background-color: $color-primary;
      transition: all .35s ease-in-out;
    }

    &--active,
    &:hover,
    &:focus {
      outline: none;
      color: $color-text-inverse-primary;

      &:after {
        width: 100%;
        left: 0px;
      }
    }
  }
}
