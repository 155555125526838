@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.product-feature-detail {
  font-family: $font-family;
  text-align: left;
  color: $color-text-inverse-primary;

  &__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: $color-primary;
    cursor: pointer;
  }

  &__headline {
    font-size: $font-size-headline;
    font-weight: $font-weight-bold;
  }

  &__icon {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 96px;
    height: 96px;
    border-radius: 96px;
    text-align: center;
    padding: 25px;
    background-color: $color-text-inverse-primary;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    line-height: $line-height;
  }
}