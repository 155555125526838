@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.header {
  position: fixed;
  z-index: 3;
  width: 100%;
  font-family: $font-family;
  background-color: $color-background-light-primary;
  box-shadow: $header-box-shadow;

  &__wrapper {
    @include container--wide;

    display: flex;
    align-items: center;
    height: 60px;
  }

  &__logo {
    display: inline-block;
    padding: 16px 60px 13px;

    & > img {
      width: 130px;
    }

    @include sm {
      padding: 16px 30px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    padding-right: 50px;

    @include md {
      width: 100%;
      justify-content: flex-end;
    }

    @include sm {
      padding-right: 10px;
    }

    &__item {
      margin-left: 5px;
    }

    &__button {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 5px;
      text-decoration: none;
      color: $color-text-inverse-primary;
      transition: color 0.3s ease-out;
      font-size: $font-size;

      &:hover {
        color: $color-action-primary;
      }

      & > .sko-icon {
        font-size: $font-size-extended;
        margin-right: 4px;
      }

      &--active {
        & > .sko-icon {
          color: $color-action-primary;
        }
      }
    }
  }
}
