.user-account-settings__headline-section {
  margin-bottom: 40px;
}

.user-account-settings__content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.user-account-settings__content-row-item {
  width: calc(50% - 15px);
  > * {
    height: 100%;
  }
}
