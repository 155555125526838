@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.cart-navigation {
  position: relative;
  padding: 50px 0;
  background-color: $color-background-dark-primary;
  box-shadow: 0px 0px 40px rgba($color: #000000, $alpha: 0.3);
  
  &__border {
    position: absolute;
    z-index: 2;
    bottom: -50px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: url('CartNavigationBorder.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 50px;
  }
}
