@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.login-box-layout__inner {
  padding-top: 80px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.login-box-layout__content-section {
  flex-grow: 1;
}

.login-box-layout__title {
  font-size: $font-size-headline;
  font-weight: $font-weight-bold;
  color: $color-text-primary;
  text-transform: uppercase;
}

.login-box-layout__subtitle {
  font-size: $font-size-h2;
  font-weight: $font-weight-light;
  color: $color-text-primary;
}