@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.cart-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  text-decoration: none;
  color: $color-text-inverse-primary;
  transition: color 0.3s ease-out;
  font-size: $font-size;

  &:hover {
    color: $color-action-primary;
  }

  & > .sko-icon {
    font-size: $font-size-extended;
    margin-right: 4px;
  }

  &--active {
    & > .sko-icon {
      color: $color-action-primary;
    }
  }

  &__counter {
    position: absolute;
    display: block;
    top: 0px;
    right: -10px;
    width: 16px;
    height: 16px;
    background-color: $color-action-primary;
    color: $color-text-primary;
    border-radius: 16px;
    font-size: 9px;
    font-weight: $font-weight-bold;
    line-height: 16px;
    text-align: center;
  }
}
