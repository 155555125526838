@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.button {
  display: inline-block;
  min-width: 175px;
  height: 44px;
  line-height: 44px;
  padding: 0 24px;

  background-color: $color-button-primary;
  box-shadow: $shadow-button-primary;
  border: none;
  border-radius: 100px;

  font-size: $font-size-button;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-text-primary;
  letter-spacing: 1px;
  cursor: pointer;
  transition: color 0.3s ease-out, border-color 0.3s ease-out, background-color 0.3s ease-out;

  &:hover {
    background-color: $color-button-primary-hover;
  }

  &:focus {
    outline: none;
    background-color: $color-button-primary-hover;
  }

  &:active {
    background-color: $color-button-primary-click;
    box-shadow: none;
  }

  &:disabled {
    background-color: $color-gray;
    color: $color-text-inverse-primary;
    box-shadow: none;
  }

  &--secondary {
    background-color: $color-background-light-primary;
    color: $color-text-inverse-primary;
    border: 1px solid $color-gray;
    box-shadow: none;

    &:hover {
      border-color: $color-text-inverse-primary;
      background-color: $color-background-light-primary;
    }

    &:focus {
      border-color: $color-text-inverse-primary;
      background-color: $color-background-light-primary;
    }

    &:active {
      background-color: $color-button-primary-click;
      color: $color-text-primary;
    }
  }

  &--thin {
    height: 28px;
    line-height: 28px;
  }

  &--back {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    @include sm {
      margin-bottom: 2rem;
    }
  }

  &__back-icon-arrow {
    &:before {
      font-weight: $font-weight-bold !important;
      margin-right: 5px;
    }
  }
}

.button-link {
  display: inline-block;
  padding: 1px 6px;
  background-color: transparent;
  border: none;

  font-size: $font-size-button;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-primary;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: underline;
  }

  &--back {
    position: relative;
    margin-left: 15px;
    line-height: 20px;
  }

  &--add {
    position: relative;
    margin-left: 20px;
    line-height: 20px;
  }
}

.button-link__back-icon {
  position: absolute;
  left: -15px;
  font-size: 20px;
}

.button-link__add-icon {
  position: absolute;
  left: -20px;
  font-size: 20px;
}