@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.product-item-box {
  display: block;
  margin-bottom: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;

  &:hover &__headline {
    text-decoration: underline;
  }

  &__link {
    position: relative;
    display: block;
    height: 100%;
    text-decoration: none;
    transition: .25s transform ease-in-out;

    &:hover {
      transform: scale(1.03);
    }
  }

  &__container {
    position: relative;
    height: 100%;
    overflow: hidden;
    z-index: 100;
  }

  &__background {
    position: absolute;
    left: -20px;
    width: 100%;
    height: 100%;
    transform: skewX(5deg);
    background-image: radial-gradient(100% 100% at 48.77% 25.14%, rgba(255, 255, 255, 0.0808566) 0%, rgba(0, 0, 0, 0.5) 100%);
  }

  &__info {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    padding: 40px 60px 40px 40px;
    font-family: $font-family;
    font-size: $font-size-extended;
    line-height: $line-height-extended;
    color: $color-text-secondary;

    &--negative {
      color: $color-text-negative;
      & .product-item-box__headline,
      & .product-item-box__price-row__value {
        color: $color-text-negative;
      }
    }
  }

  &__headline {
    margin-bottom: $font-size-base;
    font-family: $font-family;
    font-size: $font-size-headline;
    font-weight: $font-weight-bold;
    line-height: $line-height-headline;
    color: $color-text-primary;
  }

  &__description {
    margin-bottom: auto;
  }

  &__price-row {
    margin-top: auto;
    padding-top: 1rem;

    &__value {
      font-size: $font-size-h1;
      line-height: $line-height-h1;
      font-weight: $font-weight-bold;
      color: $color-text-primary;

      & > sup {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-normal;
      }
    }

    &__period {
      font-weight: 300;
    }
  }

  &--full {
    width: 100%;
    height: 400px;

    .product-item-box__container {
      width: 40%;
    }
  }

  &--medium {
    width: (100% / 3 * 2);
    height: 601px;

    .product-item-box__container {
      width: 55%;
    }

    .product-item-box__background {
      left: -25px;
      transform: skewX(4deg);
    }

    &.product-item-box--switch {
      background-position: 0 0;

      .product-item-box__container {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 50%;
      }

      .product-item-box__background {
        top: 25px;
        left: 0px;
        transform: skewY(3deg);
      }

      .product-item-box__info {
        padding: 60px 40px 40px 40px;
      }
    }
  }

  &--small {
    width: (100% / 3 * 1);
    height: 601px;

    background-position: 0 0;

    .product-item-box__container {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 50%;
    }

    .product-item-box__background {
      top: 25px;
      left: 0px;
      transform: skewY(4deg);
    }

    .product-item-box__info {
      padding: 60px 40px 40px 40px;
    }

    &.product-item-box--switch {
      background-position: 0 100%;

      .product-item-box__container {
        top: 0px;
        bottom: auto;
      }

      .product-item-box__background {
        top: -25px;
        left: 0px;
        transform: skewY(4deg);
      }

      .product-item-box__info {
        padding: 40px 40px 60px 40px;
      }
    }
  }

}