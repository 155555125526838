.user-vehicles__headline-secion {
  margin-bottom: 40px;
}

.user-vehicles__actions-secion {
  margin-top: 40px;
}

.user-vehicles__vehicle-list-item{
  margin-bottom: 20px;
}