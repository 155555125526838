@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.user-vehicle-enroll-dialog__headline-section {
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-vehicle-enroll-dialog__content-section {
  max-width: 950px;
  padding: 49px 53px;

  background-color: $color-background-light-secondary;
  text-align: center;
}

.user-vehicle-enroll-dialog__close-icon {
  font-size: 24px;
  color: $color-primary;
}

.user-vehicle-enroll-dialog__content-box {
  background-color: $color-background-light-primary;
  border: 1px solid $color-border-light-primary;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 30px 40px;
  margin-bottom: 18px;
  text-align: left;
}

.user-vehicle-enroll-dialog__title {
  margin: 10px 0px 16px;
  font-family: $font-family;
  font-size: $font-size-h1;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
}

.user-vehicle-enroll-dialog__text {
  font-family: $font-family;
  font-size: 1.125 * $font-size-base;
  color: $color-text-inverse-primary;
  max-width: 634px;
  margin-bottom: 47px;
}

.user-vehicle-enroll-dialog__input {
  max-width: 634px;
}

.user-vehicle-enroll-dialog__faq{
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.user-vehicle-enroll-dialog__actions {
  margin-top: 50px;
  > *:first-child {
    margin-right: 18px;
  }

}