@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.user-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-name__container {
  display: flex;
  flex-direction: column;
}

.user-name__name {
  margin-bottom: 5px;

  font-family: $font-family;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  color: $color-text-inverse-primary;
}

.user-name__email {
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-inverse-primary;
}

.user-name__icon {
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-right: 18px;

  background-image: url('../../static/assets/user.svg');
  background-size: cover;
}
