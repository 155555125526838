@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.footer-navigation {
  z-index: 1;
  position: relative;
  background-color: $color-background-footer-navigation;
  width: 100%;
  color: $color-text-primary;

  font-family: $font-family;

  &__inner {
    @include container;
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &__border {
    position: absolute;
    z-index: 2;
    top: -40px;
    left: 0px;
    width: 100%;
    height: 40px;
    background: url('FooterNavigationBorder.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 40px;
  }

  &__column {
    font-size: $font-size;
    line-height: $line-height;
    
    &__headline {
      font-size: $font-size;
      line-height: $line-height;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
    }

    a {
      color: $color-text-primary;
      text-decoration: none;

      & > .sko-icon {
        font-size: $font-size-extended;

        color: $color-action-primary;
        padding-right: 10px;
      }

      &:hover {
        color: $color-action-primary;
        text-decoration: underline;
      }
    }
  }
}

.footer-navigation-button {
  width: 175px;
  height: 44px;

  background-color: $color-button-primary;
  box-shadow: $shadow-button-primary;
  border: none;
  border-radius: 100px;

  font-size: $font-size-button;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-text-primary;

  &:hover {
    background-color: $color-button-primary-hover;
  }

  &:focus {
    outline: none;
    background-color: $color-button-primary-hover;
  }

  &:active {
    background-color: $color-button-primary-click;
    box-shadow: none;
  }

  &:disabled {
    background-color: $color-gray;
    color: $color-text-inverse-primary;
    box-shadow: none;
  }

  &--secondary {
    background-color: $color-background-light-primary;
    color: $color-text-inverse-primary;
    border: 1px solid $color-gray;
    box-shadow: none;

    &:hover {
      border-color: $color-text-inverse-primary;
      background-color: $color-background-light-primary;
    }

    &:focus {
      border-color: $color-text-inverse-primary;
      background-color: $color-background-light-primary;
    }

    &:active {
      background-color: $color-button-primary-click;
      color: $color-text-primary;
    }
  }
}
