[data-icon]::before {
  font-family: "skoda-icons-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-size: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon"]::before,
[class*=" icon"]::before {
  font-family: "skoda-icons-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon.icon-arrow-large-left::before {
  content: "f";
}

.icon.icon-arrow-large-right::before {
  content: "g";
}

.icon.icon-arrow-large-up::before {
  content: "h";
}

.icon.icon-arrow-medium-down::before {
  content: "i";
}

.icon.icon-arrow-medium-left::before {
  content: "j";
}

.icon.icon-arrow-medium-right::before {
  content: "k";
}

.icon.icon-arrow-medium-up::before {
  content: "l";
}

.icon.icon-arrow-small-down::before {
  content: "m";
}

.icon.icon-arrow-small-left::before {
  content: "n";
}

.icon.icon-arrow-small-right::before {
  content: "o";
}

.icon.icon-arrow-small-up::before {
  content: "p";
}

.icon.icon-bootspace::before {
  content: "q";
}

.icon.icon-car::before {
  content: "r";
}

.icon.icon-chat::before {
  content: "s";
}

.icon.icon-check::before {
  content: "t";
}

.icon.icon-close-large-white::before {
  content: "u";
}

.icon.icon-close-medium-white::before {
  content: "v";
}

.icon.icon-close-small-white::before {
  content: "w";
}

.icon.icon-contracts::before {
  content: "z";
}

.icon.icon-edit::before {
  content: "A";
}

.icon.icon-error::before {
  content: "B";
}

.icon.icon-info::before {
  content: "C";
}

.icon.icon-360view::before {
  content: "a";
}

.icon.icon-airbags::before {
  content: "c";
}

.icon.icon-minus::before {
  content: "d";
}

.icon.icon-arrow-large-down::before {
  content: "e";
}

.icon.icon-phone::before {
  content: "I";
}

.icon.icon-pin::before {
  content: "J";
}

.icon.icon-play::before {
  content: "K";
}

.icon.icon-plus::before {
  content: "L";
}

.icon.icon-preloader-small::before {
  content: "N";
}

.icon.icon-question::before {
  content: "O";
}

.icon.icon-quote-left::before {
  content: "P";
}

.icon.icon-quote-right::before {
  content: "Q";
}

.icon.icon-reload::before {
  content: "R";
}

.icon.icon-special::before {
  content: "U";
}

.icon.icon-status-ok::before {
  content: "V";
}

.icon.icon-status-warning-error::before {
  content: "W";
}

.icon.icon-user::before {
  content: "Y";
}

.icon.icon-search::before {
  content: "x";
}

.icon.icons-pause::before {
  content: "Z";
}

.icon.calendar::before {
  content: "0";
}

.icon.facebook::before {
  content: "1";
}

.icon.instagram::before {
  content: "2";
}

.icon.twitter::before {
  content: "3";
}

.icon.youtube::before {
  content: "4";
}

.icon.icon-logout::before {
  content: "E";
}

.icon.icon-message::before {
  content: "F";
}

.icon.icon-alert::before {
  content: "G";
}

.icon.icon-infocenter::before {
  content: "D";
}

.icon.icon-more::before {
  content: "H";
}

.icon.icon-air-pollution::before {
  content: "5";
}

.icon.icon-consumption::before {
  content: "y";
}

.icon.icon-max-torque::before {
  content: "8";
}

.icon.icon-power::before {
  content: "M";
}

.icon.icon-acceleration::before {
  content: "b";
}

.icon.icon-transmission::before {
  content: "6";
}

.icon.icon-energy-efficiency-class::before {
  content: "%";
}

.icon.icon-consumption-wltp::before {
  content: "/";
}

.icon.icon-co2-wltp::before {
  content: "(";
}

.icon.icon-settings::before {
  content: "T";
}

.icon.icon-settings-outline::before {
  content: "X";
}

.icon.icon-top-speed::before {
  content: "*";
}

.icon.ic-big-arrow::before {
  content: "[";
}

.icon.icon-petrol::before {
  content: "";
}

.icon.icon-icon-energy-efficiency-class-2::before {
  content: "";
}

.icon.icon-icon-acceleration-1::before {
  content: "";
}

.icon.icon-consumption-equivalent::before {
  content: "";
}

.icon.icon-icon-acceleration-2::before {
  content: "";
}

.icon.icon-co2-gas::before {
  content: "";
}

.icon.icon-gas-consumption::before {
  content: "";
}

.icon.icon-co2::before {
  content: "";
}

.icon.icon-electro-consumption::before {
  content: "";
}

.icon.icon-co2-electro::before {
  content: "";
}

.icon.icon-electro-range::before {
  content: "";
}


.sko-icon:before {
  font-family: "Skoda Icons";
  display: inline-block;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sko-icon.sko-icon-arrow-away::before {
  content: "\f101";
}
.sko-icon.sko-icon-beak-down-circle::before {
  content: "\f102";
}
.sko-icon.sko-icon-beak-down::before {
  content: "\f103";
}
.sko-icon.sko-icon-beak-left-circle::before {
  content: "\f104";
}
.sko-icon.sko-icon-beak-left::before {
  content: "\f105";
}
.sko-icon.sko-icon-beak-right-circle::before {
  content: "\f106";
}
.sko-icon.sko-icon-beak-right::before {
  content: "\f107";
}
.sko-icon.sko-icon-beak-up-circle::before {
  content: "\f108";
}
.sko-icon.sko-icon-beak-up::before {
  content: "\f109";
}
.sko-icon.sko-icon-bubbles::before {
  content: "\f10a";
}
.sko-icon.sko-icon-bullet::before {
  content: "\f10b";
}
.sko-icon.sko-icon-car-photo::before {
  content: "\f10c";
}
.sko-icon.sko-icon-cart::before {
  content: "\f10d";
}
.sko-icon.sko-icon-category::before {
  content: "\f10e";
}
.sko-icon.sko-icon-checkmark-circle::before {
  content: "\f10f";
}
.sko-icon.sko-icon-checkmark::before {
  content: "\f110";
}
.sko-icon.sko-icon-clock::before {
  content: "\f111";
}
.sko-icon.sko-icon-connectivity::before {
  content: "\f112";
}
.sko-icon.sko-icon-cross-circle::before {
  content: "\f113";
}
.sko-icon.sko-icon-cross-thin::before {
  content: "\f114";
}
.sko-icon.sko-icon-cross::before {
  content: "\f115";
}
.sko-icon.sko-icon-download::before {
  content: "\f116";
}

.sko-icon.sko-icon-arrow-left::before {
  content: "\f116";
  transform: rotate(90deg);
}

.sko-icon.sko-icon-arrow-right::before {
  content: "\f116";
  transform: rotate(-90deg);
}


.sko-icon.sko-icon-email::before {
  content: "\f117";
}
.sko-icon.sko-icon-filter::before {
  content: "\f118";
}
.sko-icon.sko-icon-form-checkbox-checked::before {
  content: "\f119";
}
.sko-icon.sko-icon-form-checkbox-unchecked::before {
  content: "\f11a";
}
.sko-icon.sko-icon-form-radio-checked::before {
  content: "\f11b";
}
.sko-icon.sko-icon-form-radio-unchecked::before {
  content: "\f11c";
}
.sko-icon.sko-icon-garage::before {
  content: "\f11d";
}
.sko-icon.sko-icon-hamburger-square-close::before {
  content: "\f11e";
}
.sko-icon.sko-icon-hamburger-square::before {
  content: "\f11f";
}
.sko-icon.sko-icon-hamburger::before {
  content: "\f120";
}
.sko-icon.sko-icon-heart::before {
  content: "\f121";
}
.sko-icon.sko-icon-info-circle::before {
  content: "\f122";
}
.sko-icon.sko-icon-alert-triangle::before {
  content: "\f139";
}
.sko-icon.sko-icon-minus::before {
  content: "\f123";
}
.sko-icon.sko-icon-new::before {
  content: "\f124";
}
.sko-icon.sko-icon-pencil::before {
  content: "\f125";
}
.sko-icon.sko-icon-percent::before {
  content: "\f126";
}
.sko-icon.sko-icon-phone::before {
  content: "\f127";
}
.sko-icon.sko-icon-pin::before {
  content: "\f128";
}
.sko-icon.sko-icon-play::before {
  content: "\f129";
}
.sko-icon.sko-icon-plus-circle::before {
  content: "\f12a";
}
.sko-icon.sko-icon-plus::before {
  content: "\f12b";
}
.sko-icon.sko-icon-question-mark-circle::before {
  content: "\f12c";
}
.sko-icon.sko-icon-resize::before {
  content: "\f12d";
}
.sko-icon.sko-icon-search::before {
  content: "\f12e";
}
.sko-icon.sko-icon-settings::before {
  content: "\f12f";
}
.sko-icon.sko-icon-share::before {
  content: "\f130";
}
.sko-icon.sko-icon-social-facebook::before {
  content: "\f131";
}
.sko-icon.sko-icon-social-instagram::before {
  content: "\f132";
}
.sko-icon.sko-icon-social-twitter::before {
  content: "\f133";
}
.sko-icon.sko-icon-social-youtube::before {
  content: "\f134";
}
.sko-icon.sko-icon-trashbin::before {
  content: "\f135";
}
.sko-icon.sko-icon-truck::before {
  content: "\f136";
}
.sko-icon.sko-icon-user::before {
  content: "\f137";
}
.sko-icon.sko-icon-user-empty::before {
  content: "\f13a";
}