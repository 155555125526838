@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.order-product-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-product-summary__image {
  width: 78px;
}

.order-product-summary__info-section {
  margin-left: 7px;
  font-family: $font-family;
}

.order-product-summary__product-name {
  font-size: $font-size-small;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
}

.order-product-summary__car {
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
  font-weight: $font-weight-bold;
}

.order-product-summary__timing {
  font-size: $font-size-tiny;
  color: $color-text-inverse-secondary;
}

.order-product-summary__price {
  flex-grow: 1;
  text-align: right;
  font-family: $font-family;
  font-size: $font-size-tiny;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
}
