.user-information-form {
  max-width: 550px;
}

.user-information-form__line {
  margin-bottom: 10px;

  &--spaced {
    margin-top: 20px;
  }
}

.user-information-form__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.user-information-form__intended {
  margin-left: 25px;
}
