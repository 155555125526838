@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.user-vehicle-card {
  position: relative;
  border: 1px solid $color-border-light-primary;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 45px 12px;

  @include sm {
    flex-direction: column;
  }
}

.user-vehicle-card__image {
  width: 406px;
  padding-right: 23px;

  @include sm {
    margin-bottom: 2rem;
  }
}

.user-vehicle-card__content {
  font-family: $font-family;

  > * {
    margin-bottom: 15px;
  }
}

.user-vehicle-card__car-name {
  font-size: 2.5 * $font-size-base;
  color: $color-text-inverse-primary;
  font-weight: $font-weight-bold;
}

.user-vehicle-card__car-vin {
  font-size: 1.125 * $font-size-base;
  color: $color-text-inverse-secondary;
}

.user-vehicle-card__car-status {
  font-size: $font-size;

  &--success {
    color: $color-primary;
  }

  &--error {
    color: $color-text-inverse-secondary;
  }
}

.user-vehicle-card__external-icon {
  display: inline-block;
  // width: 8px;
  // height: 8px;
  // border: 1px solid $color-primary;
  margin-left: 12px;
  color: $color-primary;
  font-size: 10px;

  &.sko-icon::before{
    font-weight: bold;
  }
}

.user-vehicle-card__actions {
  margin-top: 42px;
}

.user-vehicle-card__compatibility-badge {
  position: absolute;
  top: 35px;
  left: 35px;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  &--success {
    background-color: $color-primary;

    &:after {
      content: "";
      position: absolute;
      top: 10px;
      left: 8px;
      width: 14px;
      height: 7px;
      border: solid white;
      border-width: 0px 0px 2px 2px;
      transform: rotate(-50deg);
    }
  }

  &--error {
    background-color: $color-error;

    &:before {
      content: "";
      position: absolute;
      top: 14px;
      left: 6px;
      width: 18px;
      height: 2px;
      background-color: white;
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      position: absolute;
      top: 14px;
      left: 6px;
      width: 18px;
      height: 2px;
      background-color: white;
      transform: rotate(-45deg);
    }
  }
}