@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.product-detail {
  display: flex;
  position: relative;
  min-height: 100%;
  padding-bottom: 60px;

  &__container {
    @include container;

    position: relative;
    z-index: 10;
    padding-right: $container-width/2;
  }

  &__image {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &__background {
    position: absolute;
    z-index: 5;
    right: 50%;
    width: 100%;
    height: 100%;
    transform: skewX(3deg);
    background-image: radial-gradient(100% 100% at 48.77% 25.14%, rgba(255, 255, 255, 0.0808566) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-color: rgb(170, 104, 51);
  }

  &__info {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    padding: 40px;
    font-family: $font-family;
    font-size: $font-size-extended;
    line-height: $line-height-extended;
    color: $color-text-primary;

    &--negative {
      color: $color-text-negative;
      & .product-detail__headline,
      & .product-detail__price-row__value {
        color: $color-text-negative;
      }
    }
  }

  &__headline {
    margin-bottom: $font-size-base;
    font-family: $font-family;
    font-size: $font-size-headline;
    font-weight: $font-weight-bold;
    line-height: $line-height-headline;
    color: $color-text-primary;
  }

  &__description {
    margin-bottom: 40px;
    margin-right: 4rem;
  }

  &__price-row {
    margin-bottom: 40px;
    &__value {
      font-size: $font-size-h1;
      line-height: $line-height-h1;
      font-weight: $font-weight-bold;
      color: $color-text-primary;

      & > sup {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-normal;
      }
    }

    &__period {
      font-weight: 300;
    }
  }

  &__features {
    margin-bottom: auto;

    & > h3 {
      margin-bottom: 40px;
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
      line-height: $line-height-h2;
    }
  }

  &__feature-list {
    max-width: 420px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__compatibility-note {
    margin-top: auto;
    padding-top: 1rem;

    font-size: $font-size-small;
    line-height: $line-height-small;
  }
}