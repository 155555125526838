@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.navigation {
  display: flex;
  border-left: 1px solid #E5E5E5;
  height: 100%;
  font-family: $font-family;
  margin-right: auto;

  @include md {
    display: none;
  }

  &__button {
    display: inline-block;
    height: 100%;
    padding: 0 25px;
    border-right: 1px solid #E5E5E5;
    font-size: $font-size;
    line-height: 58px;
    text-decoration: none;
    color: $color-text-inverse-primary;
    transition: color 0.3s ease-out;

    &:hover {
      color: $color-action-primary;
    }

    &--active {
      box-shadow: inset 0px 3px 0px $color-action-primary;
    }

    //hamburger menu
    &__menu {
      position: absolute;
      top: 30%;
      right: 5%;
      display: none;

      &:before {
        content: "\f120";
        color: $color-primary;
        font-size: $font-size-h2;
      }
    }
  }
}
