@import '../../styles/mixins.scss';

.billing-info-form {
  max-width: 550px;
}

.billing-info-form__line {
  margin-bottom: 0.625rem;

  &__break {
    margin-bottom: 2rem;
  }

  &--spaced {
    margin-top: 1rem;
  }
}

.billing-info-form__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3.125rem;

  @include sm {
    flex-direction: column;
    justify-content: center;
  }
}

.billing-info-form__intended {
  margin-left: 1.5625rem;
}