@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';


.progress-bar {
  @include container;

  color: $color-text-inverse-secondary;
  font-family: $font-family;
  font-size: $font-size-small;
}

.progress-bar__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar__wrapper {
  counter-reset: step;
  list-style: none;
}

.progress-bar__item {
  float: left;
  width: 180px;
  position: relative;
  text-align: center;

  @include md {
    width: 100px;
  }

  @include sm {
    width: 60px;
  }
}

.progress-bar__item:before {
  content:counter(step);
  counter-increment: step;
  position: relative;
  display: block;
  z-index: 2;
  width: 20px;
  height: 20px;
  margin: 0 auto 6px auto;
  border: 1px solid $color-text-inverse-secondary;
  border-radius: 20px;
  font-size: 12px;
  line-height: 19px;
  background: $color-background-dark-primary;
  color: $color-text-inverse-secondary;
}

.progress-bar__item:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: $color-text-inverse-secondary;
  top: 10px;
  left: -50%;
  z-index: 1;
}

.progress-bar__item:first-child:after {
  content: none;
}

.progress-bar__item--active {
  color:$color-background-light-primary;
  font-weight: $font-weight-bold;
}

.progress-bar__item--active:before {
  border-color: $color-background-light-primary;
  background-color: $color-background-light-primary;
  color: $color-background-dark-primary;
  font-weight: $font-weight-bold;
}

.progress-bar__item--done {
  color: $color-text-inverse-secondary;
}

.progress-bar__item--done:before {
  border-color: $color-text-inverse-secondary;
  background-color: $color-text-inverse-secondary;
  color: $color-background-light-primary;
  //using skoda auto icons from icons.scss
  font-family: "Skoda Icons";
  content: '\f110';
}

.progress-bar__item__label {
  @include sm {
    display: none;
  }
}