@import '../../styles/mixins.scss';

.orders__headline-section {
  margin-bottom: 40px;
}

.orders__tabs-section {
  margin-bottom: 30px;
}

.orders__order-list-section {
  margin-bottom: 40px;
}

.orders__actions-section {
  position: relative;
  text-align: center;

  @include sm {
    height: 100px;
  }
}

.orders__pagination {
  position: absolute;
  right: 0;
  top: 8px;

  @include sm {
    top: 70px;
    left: 0;
  }
}
