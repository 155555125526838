@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.user-consent-management {
  border: 1px solid $color-border-light-secondary;
  box-shadow: $box-shadow;
  padding: 24px;
}

.user-consent-management__disclaimer {
  margin-left: 25px;
  margin-top: 15px;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-secondary;
  line-height: 1.4;
}
