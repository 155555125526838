@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.link {
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-primary;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: underline;
  }

  &--download {
    position: relative;
    margin-left: 20px;
  }
}

.link__download-icon {
  position: absolute;
  left: -20px;

  font-size: 16px;
  &.sko-icon::before{
    font-weight: bold;
  }
}
