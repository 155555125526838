@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.payment-method-indent-payment {
  font-family: $font-family;
  color: $color-text-inverse-primary;
}

.payment-method-indent-payment__image {
  margin-left: 3.25rem;
}

.payment-method-indent-payment__info-text {
  margin-bottom: 4px;
  margin-left: 3.25rem;
  margin-top: $spacing-offset;
  font-size: $font-size-small;
}

.payment-method-indent-payment__selection-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  &__text {
    flex-grow: 1;
    margin-left: 3.25rem;
    font-size: $font-size-base;
  }

  &__price {
    font-size: $font-size-small;
  }
}

.payment-method-indent-payment__selection {
  padding: $spacing-offset;
}

.payment-method-indent-payment__selection-item {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

/* Hide the browser's default radio button */
.payment-method-indent-payment__selection-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.payment-method-indent-payment__selection-item__checkmark {
  position: absolute;
  top: 18px;
  left: 16px;
  width: 16px;
  height: 16px;
  border: solid 1px #e5e5e5;
  border-radius: 8px;
  cursor: pointer;
}

.payment-method-indent-payment__selection-item__background {
  padding: 1rem 0;
}

.payment-method-indent-payment__selection-item input:checked ~ .payment-method-indent-payment__selection-item__background .payment-method-indent-payment__selection-item__checkmark {
  background-color: $color-action-primary;
  border: solid 1px $color-action-primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.payment-method-indent-payment__selection-item__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.payment-method-indent-payment__selection-item input:checked ~ .payment-method-indent-payment__selection-item__background .payment-method-indent-payment__selection-item__checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.payment-method-indent-payment__selection-item .payment-method-indent-payment__selection-item__checkmark:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

​​​​​​ input:checked {
  text-decoration: none;
}

input:checked~.payment-method-indent-payment__selection-item__background .payment-method-indent-payment__selection-item__price {
  color: $color-action-primary;
}

input:checked~.payment-method-indent-payment__selection-item__background .payment-method-indent-payment__selection-item__text {
  font-weight: $font-weight-bold;
}

.payment-method-indent-payment__selection-item input:checked ~ .payment-method-indent-payment__selection-item__background {
  background-color: #F4F6F6;
  border: 1px solid #E5E5E5;
}

.payment-method-indent-payment__info-box {
  display: flex;
  align-items: center;
  border: 1px solid rgba(142, 140, 68, 0.206922);
  margin-top: 2.5rem;
  padding: $spacing-offset;
  background-color: $color-background-info;
  margin-bottom: 103px;

  &__text {
    flex-basis: 65%;
    margin-left: $spacing-offset;
    font-size: $font-size-small;
  }
}

.payment-method-indent-payment__button-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    justify-content: center;
  }
}