@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/breakpoints.scss';

.login-form {
  font-family: $font-family;
  text-align: left;
  color: $color-text-inverse-primary;
  padding: 20px 60px;

  @include sm {
    padding: 20px;
  }

  &__subtitle {
    margin-top: 10px;
    margin-bottom: 50px;
    opacity: 0.6;

    @include md {
      text-align: center;
    }

    @include sm {
      margin-bottom: 20px;
    }
  }

  &__actions {
    margin-top: 50px;
    text-align: center;

    & > .button {
      margin-bottom: 1rem;
    }

    & > .button-link {
      color: $color-text-inverse-primary;
      margin-bottom: 1rem;
    }
  }
}
