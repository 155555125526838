@import './breakpoints.scss';

$container-width: 1145px;
$container-wide-width: 1300px;

@mixin container($width: $container-width) {
  max-width: $width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin container--wide($width: $container-wide-width) {
  @include container;

  max-width: $width;
}
