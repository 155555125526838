@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.shopping-cart-product-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $line-height;
  margin-bottom: $line-height;

  @include sm {
    flex-direction: column;
  }

  &__image {
    width: 114px;
    height: 72px;
    object-fit: cover;

    @include md {
      width: 80px;
      height: 50px;
    }

    @include sm {
      margin-bottom: 1rem;
    }
  }

  &__info-section {
    margin-left: $line-height;
    font-family: $font-family;

    @include sm {
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  &__product-name {
    font-size: $font-size-h3;
    color: $color-text-inverse-primary;
    font-weight: $font-weight-bold;
    line-height: $line-height;

    @include md {
      font-size: $font-size-h4;
    }
  }

  &__car-type {
    font-size: $font-size-small;
    color: $color-text-inverse-primary;
    font-weight: $font-weight-bold;
    line-height: $line-height;
  }

  &__car-vin {
    font-size: $font-size-tiny;
    color: $color-text-inverse-secondary;
    line-height: $line-height;
  }

  &__timing {
    font-size: $font-size-h4;
    color: $color-text-inverse-primary;
    font-family: $font-family;
    flex-grow: 3;
    text-align: right;

    @include sm {
      margin-bottom: 1rem;
    }
  }

  &__price-section {
    display: flex;
    flex-grow: 2;

    @include sm {
      margin-bottom: 1rem;
    }

    &__price {
      flex-grow: 1;
      text-align: right;
      font-family: $font-family;
      font-size: $font-size-h3;
      color: $color-text-inverse-primary;
      font-weight: $font-weight-bold;

      @include md {
        font-size: $font-size-h4;
      }

      &__currency {
        font-family: $font-family;
        font-size: $font-size-tiny;
        color: $color-text-inverse-primary;
        margin-left: 3px;
        margin-top: 2px;
        margin-right: $line-height-h1;
      }
    }
  }

  &__trash-icon {
    background-image: url('../../static/assets/trash.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 23px;
  }
}