@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.order-summary-user-consent-management {
  margin-bottom: 30px;
}

.order-summary-user-consent-management__disclaimer {
  margin-left: 25px;
  margin-top: 4px;
  margin-bottom: 30px;
  font-family: $font-family;
  font-size: $font-size-small;
  color: $color-text-inverse-secondary;
  line-height: 1.4;
}