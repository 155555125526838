@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.product-feature-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 24px;
  color: $color-text-primary;
  text-align: center;
  text-decoration: none;
  font-size: $font-size-small;
  line-height: $line-height-small;

  &__button {
    display: inline-block;
    overflow: hidden;
    width: 64px;
    height: 64px;
    border: 0;
    border-radius: 64px;

    background-color: rgba($color: $color-background-dark-primary, $alpha: 0.5);    
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: rgba($color: $color-background-dark-primary, $alpha: 1);
      transition: background-color 0.3s ease-out;
    }

    & > img {
      position: relative;
      top: 50%;
      margin-top: -16px;
      width: 32px;
      height: 32px;
    }
  }

  &__description {
    display: block;
    padding-top: 10px;
  }
}
