@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.user-personal-information {
  border: 1px solid $color-border-light-secondary;
  box-shadow: $box-shadow;
  padding: 24px;
}

.user-personal-information__line {
  font-size: $font-size;
  color: $color-text-inverse-primary;
  line-height: 2;

  &--actions {
    text-align: right;
    line-height: 1;
    margin: 7px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

}

.user-personal-information__line-title {
  display: inline-block;
  font-weight: $font-weight-bold;
  width: 90px;
}

