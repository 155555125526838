@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.user-account-page {
  display: flex;
  min-height: 100%;
  flex-direction: row;
  align-items: stretch;

  @include md {
    flex-direction: column;
  }
}

.user-account-page__nav-section {
  display: flex;
  justify-content: center;
  padding: 60px 20px;
  width: 30%;
  background-color: $color-background-light-secondary;

  @include md {
    width: 100%;
    padding: 20px 20px;
  }
}

.user-account-page__content-section {
  padding: 68px 50px;
  flex-grow: 1;
  background-color: $color-background-light-primary;

  @include sm {
    padding: 68px 16px;
  }
}

.user-account-page__content-wrapper {
  max-width: 850px;
}
