@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.order-price-summary {
  font-family: $font-family;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  color: $color-text-inverse-secondary;
  line-height: 1.7;
}

.order-price-summary__item {
  text-align: right;
}

.order-price-summary__label {
  &--strong {
    color: $color-text-inverse-primary;
    font-size: $font-size-h2 ;
    line-height: 1.5;
  }
}

.order-price-summary__value {
  padding-left: 40px;

  &--strong {
    color: $color-text-inverse-primary;
    font-size: $font-size-h2;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
}
